<template>
  <div>
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold;font-size: 16px;line-height: normal;">充幣</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div style="padding: 20px;" class="Recharge">
      <van-cell-group inset>
        <van-field v-model="text" placeholder="請輸入充幣數量" />
      </van-cell-group>
      <span style="font-size: 10px;margin-left: 10%;color:orangered;">請使用幣安智能鏈進行充值</span>
      <van-button style="background-color: #AC76FA;color: #fff;font-weight: bold;" size="large" class="butsty"
        @click="showPopup">確認</van-button>
    </div>
  </div>

 <!--充值弹框彈框開始 -->
  <div v-if="show" style="margin-top: -10%;position: absolute;margin-left: 15%;width: 100%;" >
   <img src="../../assets/images/faqbg.png" alt="" style="width: 70%;"/>
   <div style="position: absolute;font-size: 0.4rem;width: 60%;margin-top: -77%;margin-left: 5%;">
     <h2 style="margin-left: 30%;">通道類型</h2>
        <input  style="margin-top: 5%;" type="radio" id="option1" name="chain_type"  value="1" v-model="chain_type">
          <label style="margin-left: 5%;font-weight: bold;" for="option1">BEP-20</label><br>

          <input style="margin-top: 5%;" type="radio" id="option2" name="chain_type"  value="2" v-model="chain_type" >
          <label style="margin-left: 5%;font-weight: bold;" for="option2">Roc-20</label><br>
   </div>
   <div @click="sendAll" style="border-radius: 10px;background-color: #B791FA;width: 20%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-top: -11%;position: absolute;margin-left: 8%;">確認</div>
       <div @click="closePopup" style="border-radius: 10px;background-color: #B791FA;width: 30%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-left: 33%;margin-top: -11%;position: absolute;">考慮一會</div>
  </div>
  <!--充值弹框结束 -->
</template>

<script setup>
  import {
    ref,
    reactive
  } from 'vue';
  import {
    detailed
  } from "@/utils/api.js";
  import {
    usdtabi
  } from '@/utils/abi.js'
  import Web3 from "@/utils/web3.min.js";
  import {
    useRouter
  } from "vue-router";
  import {
    precision
  } from '@/utils/precision.js';
  import {
    Toast
  } from 'vant';
  import {
    Notify
  } from 'vant';

  const page = ref(1)
  const page_size = ref(50)
  const detailedlist = ref([])
  const time = ref([])
  const times = ref([])
  const text = ref()
  const onClickLeft = () => history.back();
  const router = useRouter();
  const add = ref('')
  const show = ref(false)
  const chain_type = ref()

function showPopup(){
    show.value = true;
}

function closePopup(){
    show.value = false;
}

 async function sendAll() {
     if (chain_type.value == 1 && router.currentRoute.value.query.amount == 1){
       send();
     }else if (chain_type.value == 2 && router.currentRoute.value.query.amount == 1){
       Toast({
         message: '敬請期待',
         icon: 'fail',
         className: 'noticeWidth'
       });
       return
     }else if (chain_type.value == 1 && router.currentRoute.value.query.amount == 2 ){
       Toast({
         message: '維護中....',
         icon: 'fail',
         className: 'noticeWidth'
       });
       return
     }else{
       sendRoacChainRoc();
     }
 }

  //转账ROC链转主币
  async function sendRoacChainRoc() {
    if (text.value == null) {
      Toast({
        message: '请输入数量',
        icon: 'fail',
        className: 'noticeWidth'
      });
      return
    }
    const web3 = new Web3(window.ethereum);
    ethereum.enable()
    //获取地址
    let accounts = await web3.eth.getAccounts();
    let fromAddress = accounts[0];

    const a = await web3.eth.getGasPrice()
    console.log("地址:" + fromAddress, text.value, a, router.currentRoute.value.query.amount)
    //收R地址

    let toAddress = "0x39c6E879f6796Bee05A76EF03F8152d9002Dbe7a";


    const shouqian = toAddress
    // 查询代币余额
    // const result = await tokenContract.methods.balanceOf(fromAddress).call()
    // console.log(result);
    // if (Number(result) == 0) {
    //   Toast({
    //     message: '余额不足',
    //     icon: 'fail',
    //     className: 'noticeWidth'
    //   });
    //   return
    // }

    web3.eth.sendTransaction({
      gas: 101323,
      gasPrice: a,
      from: fromAddress,
      value:text.value + '000000000000000000',
      to: shouqian,
    }, function(error, hash) {
      console.log(error, hash)
    });

    Toast({
      message: '操作成功待链上确认',
      icon: 'success',
      className: 'noticeWidth'
    });

  }

  //转账ROC链ROC-20协议开始
  async function sendroc20() {
    if (text.value == null) {
      Toast({
        message: '请输入数量',
        icon: 'fail',
        className: 'noticeWidth'
      });
      return
    }
    const web3 = new Web3(window.ethereum);
    ethereum.enable()
    //获取地址
    let accounts = await web3.eth.getAccounts();
    let fromAddress = accounts[0];

    const a = await web3.eth.getGasPrice()
    console.log("地址:" + fromAddress, text.value, a, router.currentRoute.value.query.amount)
    //支付代笔地址
    //usdt
    let toAddress = "0xC2c0cA0Bdcd18696CC56F6b1CB97bB3f90Fa8231";
    //roc
    let toAddresstwo = "0x1a72E23dE3942e74F166A039f3Dd893C3687693C";

    //usdt收款地址
    let usdtshou = "0xE14C9850C9dFe7633718923E86fF103974572296";
    //roc收款地址
    let rocshou = "0x98897280e7B3eFDF6a2079dE8B663E10C88009ee";

    const shouqian = router.currentRoute.value.query.amount == 1 ? usdtshou : rocshou
    // 动态化的数据
    let dynamicData = {
      to: shouqian,
      value: text.value * precision
    };

    // 将动态化的数据转换为十六进制字符串
    let datas = web3.eth.abi.encodeFunctionCall({
      name: 'transfer',
      type: 'function',
      inputs: [{
          type: 'address',
          name: 'to'
        },
        {
          type: 'uint256',
          name: 'value'
        }
      ]
    }, [dynamicData.to, dynamicData.value]);


    const tokenABI = [{
      "constant": true,
      "inputs": [],
      "name": "name",
      "outputs": [{
        "name": "",
        "type": "string"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "_upgradedAddress",
        "type": "address"
      }],
      "name": "deprecate",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "_spender",
        "type": "address"
      }, {
        "name": "_value",
        "type": "uint256"
      }],
      "name": "approve",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "deprecated",
      "outputs": [{
        "name": "",
        "type": "bool"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "_evilUser",
        "type": "address"
      }],
      "name": "addBlackList",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "totalSupply",
      "outputs": [{
        "name": "",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "_from",
        "type": "address"
      }, {
        "name": "_to",
        "type": "address"
      }, {
        "name": "_value",
        "type": "uint256"
      }],
      "name": "transferFrom",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "upgradedAddress",
      "outputs": [{
        "name": "",
        "type": "address"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [{
        "name": "",
        "type": "address"
      }],
      "name": "balances",
      "outputs": [{
        "name": "",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "decimals",
      "outputs": [{
        "name": "",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "maximumFee",
      "outputs": [{
        "name": "",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "_totalSupply",
      "outputs": [{
        "name": "",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [],
      "name": "unpause",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [{
        "name": "_maker",
        "type": "address"
      }],
      "name": "getBlackListStatus",
      "outputs": [{
        "name": "",
        "type": "bool"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [{
        "name": "",
        "type": "address"
      }, {
        "name": "",
        "type": "address"
      }],
      "name": "allowed",
      "outputs": [{
        "name": "",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "paused",
      "outputs": [{
        "name": "",
        "type": "bool"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [{
        "name": "who",
        "type": "address"
      }],
      "name": "balanceOf",
      "outputs": [{
        "name": "",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [],
      "name": "pause",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "getOwner",
      "outputs": [{
        "name": "",
        "type": "address"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "owner",
      "outputs": [{
        "name": "",
        "type": "address"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "symbol",
      "outputs": [{
        "name": "",
        "type": "string"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "_to",
        "type": "address"
      }, {
        "name": "_value",
        "type": "uint256"
      }],
      "name": "transfer",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "newBasisPoints",
        "type": "uint256"
      }, {
        "name": "newMaxFee",
        "type": "uint256"
      }],
      "name": "setParams",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "amount",
        "type": "uint256"
      }],
      "name": "issue",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "amount",
        "type": "uint256"
      }],
      "name": "redeem",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [{
        "name": "_owner",
        "type": "address"
      }, {
        "name": "_spender",
        "type": "address"
      }],
      "name": "allowance",
      "outputs": [{
        "name": "remaining",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "basisPointsRate",
      "outputs": [{
        "name": "",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [{
        "name": "",
        "type": "address"
      }],
      "name": "isBlackListed",
      "outputs": [{
        "name": "",
        "type": "bool"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "_clearedUser",
        "type": "address"
      }],
      "name": "removeBlackList",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": true,
      "inputs": [],
      "name": "MAX_UINT",
      "outputs": [{
        "name": "",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "newOwner",
        "type": "address"
      }],
      "name": "transferOwnership",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "constant": false,
      "inputs": [{
        "name": "_blackListedUser",
        "type": "address"
      }],
      "name": "destroyBlackFunds",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "inputs": [{
        "name": "_initialSupply",
        "type": "uint256"
      }, {
        "name": "_name",
        "type": "string"
      }, {
        "name": "_symbol",
        "type": "string"
      }, {
        "name": "_decimals",
        "type": "uint256"
      }],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "constructor"
    }, {
      "anonymous": false,
      "inputs": [{
        "indexed": false,
        "name": "amount",
        "type": "uint256"
      }],
      "name": "Issue",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [{
        "indexed": false,
        "name": "amount",
        "type": "uint256"
      }],
      "name": "Redeem",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [{
        "indexed": false,
        "name": "newAddress",
        "type": "address"
      }],
      "name": "Deprecate",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [{
        "indexed": false,
        "name": "feeBasisPoints",
        "type": "uint256"
      }, {
        "indexed": false,
        "name": "maxFee",
        "type": "uint256"
      }],
      "name": "Params",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [{
        "indexed": false,
        "name": "_blackListedUser",
        "type": "address"
      }, {
        "indexed": false,
        "name": "_balance",
        "type": "uint256"
      }],
      "name": "DestroyedBlackFunds",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [{
        "indexed": false,
        "name": "_user",
        "type": "address"
      }],
      "name": "AddedBlackList",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [{
        "indexed": false,
        "name": "_user",
        "type": "address"
      }],
      "name": "RemovedBlackList",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [{
        "indexed": true,
        "name": "owner",
        "type": "address"
      }, {
        "indexed": true,
        "name": "spender",
        "type": "address"
      }, {
        "indexed": false,
        "name": "value",
        "type": "uint256"
      }],
      "name": "Approval",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [{
        "indexed": true,
        "name": "from",
        "type": "address"
      }, {
        "indexed": true,
        "name": "to",
        "type": "address"
      }, {
        "indexed": false,
        "name": "value",
        "type": "uint256"
      }],
      "name": "Transfer",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [],
      "name": "Pause",
      "type": "event"
    }, {
      "anonymous": false,
      "inputs": [],
      "name": "Unpause",
      "type": "event"
    }];
    const b = router.currentRoute.value.query.amount == 1 ? toAddress : toAddresstwo
    const tokenContract = new web3.eth.Contract(tokenABI, b);

    // 查询代币余额
    // const result = await tokenContract.methods.balanceOf(fromAddress).call()
    // console.log(result);
    // if (Number(result) == 0) {
    //   Toast({
    //     message: '余额不足',
    //     icon: 'fail',
    //     className: 'noticeWidth'
    //   });
    //   return
    // }

    web3.eth.sendTransaction({
      gas: 101323,
      gasPrice: a,
      from: fromAddress,
      to: b,
      data: datas,
    }, function(error, hash) {
      console.log(error, hash)
    });

    Toast({
      message: '操作成功待链上确认',
      icon: 'success',
      className: 'noticeWidth'
    });

  }
  //转账ROC链ROC-20协议结束

  //转账BSC链
  async function send() {
    if (text.value == null) {
      Toast({
        message: '请输入数量',
        icon: 'fail',
        className: 'noticeWidth'
      });
      return
    }
    const web3 = new Web3(window.ethereum);
    ethereum.enable()
    //获取地址
    let accounts = await web3.eth.getAccounts();
    let fromAddress = accounts[0];

    const a = await web3.eth.getGasPrice()
    console.log("地址:" + fromAddress, text.value, a, router.currentRoute.value.query.amount)
    //支付代笔地址
    //usdt
    let toAddress = "0x55d398326f99059fF775485246999027B3197955";
    //roc
    let toAddresstwo = "0x1a72E23dE3942e74F166A039f3Dd893C3687693C";

    //usdt收款地址
    let usdtshou = "0x027F3943E2F5760EB1CE60011B7518128E131404";
    //roc收款地址
    let rocshou = "0x98897280e7B3eFDF6a2079dE8B663E10C88009ee";

    const shouqian = router.currentRoute.value.query.amount == 1 ? usdtshou : rocshou
    // 动态化的数据
    let dynamicData = {
      to: shouqian,
      value: text.value * precision
    };

    // 将动态化的数据转换为十六进制字符串
    let datas = web3.eth.abi.encodeFunctionCall({
      name: 'transfer',
      type: 'function',
      inputs: [{
          type: 'address',
          name: 'to'
        },
        {
          type: 'uint256',
          name: 'value'
        }
      ]
    }, [dynamicData.to, dynamicData.value]);


    const tokenABI = [{
      "constant": true,
      "inputs": [{
        "name": "_owner",
        "type": "address"
      }],
      "name": "balanceOf",
      "outputs": [{
        "name": "balance",
        "type": "uint256"
      }],
      "type": "function"
    }];
    const b = router.currentRoute.value.query.amount == 1 ? toAddress : toAddresstwo
    const tokenContract = new web3.eth.Contract(tokenABI, b);

    // 查询代币余额
    const result = await tokenContract.methods.balanceOf(fromAddress).call()
    console.log(result);
    if (Number(result) == 0) {
      Toast({
        message: '余额不足',
        icon: 'fail',
        className: 'noticeWidth'
      });
      return
    }

    web3.eth.sendTransaction({
      gas: 101323,
      gasPrice: a,
      from: fromAddress,
      to: b,
      data: datas,
    }, function(error, hash) {
      console.log(error, hash)
    });

    Toast({
      message: '操作成功待链上确认',
      icon: 'success',
      className: 'noticeWidth'
    });

  }
</script>

<style lang="scss" scoped>
  .van-nav-bar__content {
    height: 1rem !important;
  }

  .butsty {
    height: 80px;
    font-size: 30px;
    padding: 30px;
    border-radius: 20px;
  }

  .noticeWidth {
    width: 30% !important;
    font-size: 32px !important;
  }

  ::v-deep .van-cell {
    padding: 50px !important;
  }

  ::v-deep .van-cell-group--inset {
    border-radius: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-weight: bold !important;
    font-size: 30px !important;
    height: 80px;
  }

  ::v-deep .van-nav-bar__content {
    padding-top: 30px;
    height: 80px;
    // margin: auto;
  }

  ::v-deep .van-nav-bar__arrow {
    font-size: 46px !important;
    color: #000;
  }

  .van-dropdown-menu__bar {
    box-shadow: none !important;
  }

  .van-nav-bar__content {
    box-shadow: none !important;
  }

  .van-hairline--bottom:after {
    border-bottom-width: 0 !important;
  }

  .van-collapse-item {
    border: 1px red solid;
  }

  .van-popup {
    // position: absolute !important;
  }

  .van-dropdown-menu__title {
    line-height: normal !important;
  }

  .van-dropdown-menu__bar {
    height: 60px !important;
  }

  .van-nav-bar__left {
    padding: 0rem 0.3rem 0rem 0.3rem !important;
  }

  .van-ellipsis {
    font-weight: bold !important;
    overflow: visible;
  }
</style>
